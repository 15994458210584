// vue
import { ref, computed, reactive, watch, onMounted } from 'vue';
import moment from 'moment';

// services
import BotsService from'@services/bots';
import StatsService from '@services/statsService';
import LocalStorage from '@services/ts/localStorage';

// shared
import { CRUD_PAGER_FACTORY } from '@shared/factories';

// i18n
import { useI18n } from 'vue-i18n';

// router
import { useRoute, useRouter } from 'vue-router';

// UI
import { useMessage, useNotification } from 'naive-ui';

// store
import { useGl } from '@store/ts/gl';
import { useEnv } from '@store/ts/env';
import { useRefs } from '@store/ts/refs';
import { useStats } from '@store/stats';
import { useConfirm } from '@store/confirm';

export default function (props, context) {
    // store
    const gl = useGl();
    const env = useEnv();
    const refs = useRefs();
    const stats = useStats();
    const confirm = useConfirm();
    
    const notification = useNotification();

    const route = useRoute();
    const router = useRouter();

    // i18n
    const { t } = useI18n();

    // vars
    const { _ } = window;
    const botShortInfo = ref();
    const init = ref(false);
    const totalUpdateLoading = ref(false);

    const confirmLabel = ref();
    const declineMessage = ref();
    const genConfCheck = ref(false);
    const markLastAsCanceled = ref(false);
    const RE_SET_FIX_ORDER_FACTORY = id => ({
        rate_to_finish_cycle: 0,
        rate_to_get_profit_hint: '',
        rate_to_get_profit: '',
        new_rate_to_finish_cycle: '',
        new_rate_to_get_profit_hint: '0%',
        new_rate_to_finish_cycle_suggest: 'Please, calculate',
    
        stop_bot_after_fix_completed: false,
    });
    const reSetFixOrder = ref(RE_SET_FIX_ORDER_FACTORY());

    const loadings = reactive({
        table: false,
        clearCycles: false,
        tableOrders: false,
    });
    
    const filters = reactive(CRUD_PAGER_FACTORY({
        bots: [+route.params.id],
        period: moment().subtract(60, 'days').format('YYYY-MM-DD') + ' ' + moment().add(7, 'days').format('YYYY-MM-DD'),
        statuses: [-1],
    }, {
        perPage: window.isValidJSON(LocalStorage.getItem('__filters_cycles_table'))
            ? JSON.parse(LocalStorage.getItem('__filters_cycles_table'))
            : 10,
    }));
    const filtersChanged = computed(() => {
        return filters.filters.statuses[0] !== -1;
    });
    const resetFilters = () => {
        filters.filters.statuses = [ -1 ];
            
        cyclesGet();
    };
    const refFilters = computed(() => [
        {
            mode: 'radio_group',
            title: stats.localization['trade_statistics_filters_statuses'],
            refs: stats.cycleFilters,
            value: filters.filters.statuses[0],
            filter: 'filters.statuses',
        }, {
            type: 'render',
            mode: 'date_picker',
            value: periods.value,
            filter: 'filters.period',
        },
    ]);

    const isLong = computed(() => botShortInfo.value?.algo == 'long');

    const statsOrdersDataTablePair = computed(() => stats.ordersDataTable?.records[0].pair.split('/'));

    const buyedPrice = computed(() => {
        const currentCycle = stats.currentCycle;

        const record = stats.cyclesDataTable?.records.find(({ number_id }) => number_id == currentCycle)?.prices;
        
        const price = record != 'NA' ? record.split('\n')[2] : null;

        return price != null ? parseFloat(Number(price).toFixed(6)) : '---';
    });

    const buyedPriceAll = computed(() => {
        const list = stats.ordersDataTable?.records.filter(({ status, order_type }) => status == 1 && order_type == (isLong.value ? 'buy' : 'sell'));

        const result = list.reduce((accum, value) => accum + (value.rate * value.amount_processed), 0);

        return parseFloat(result.toFixed(6));
    });

    const buyedVolume = computed(() => {
        const list = stats.ordersDataTable?.records.filter(({ status, order_type }) => status == 1 && order_type == (isLong.value ? 'buy' : 'sell'));

        const result = list.reduce((accum, value) => accum + +value.amount_processed, 0);

        return parseFloat(result.toFixed(6));
    });

    const averagePrice = computed(() => {
        return parseFloat((buyedPriceAll.value / buyedVolume.value).toFixed(6));
    });

    const buyedVolumeLabel = computed(() => isLong.value
        ? (stats.localization?.coins_purchased || 'Куплено монет')
        : (stats.localization?.coins_sold || 'Продано монет'),
    );

    const optionsStatuses = computed(() => {
        const arr = stats.cycleFilters.map(({ id, title }) => ({
            value: id,
            label: title,
        })).sort(({ value }, { value: value2 }) => value - value2);

        arr.unshift({
            value: -1,
            label: stats.localization['trade_statistics_filters_bots_all_button'],
        });

        return arr;
    });

    const ordersFilters = reactive(CRUD_PAGER_FACTORY({
        bots: [+route.params.id],
    }, {
        perPage: 50,
    }));

    const periods = computed(() => {
        const arr = filters.filters.period.split(' ');

        return [new Date(arr[0]).getTime(), new Date(arr[1]).getTime()];
    });

    const buttons = computed(() => [
        {
            show: true,
            loading: false,
            label: stats.localization['trade_statistics_management_start_button'],
            fn() {
                canDoAction('start');
            },
        }, {
            show: true,
            loading: false,
            label: stats.localization['trade_statistics_management_restart_button'],
            fn() {
                canDoAction('restart');
            },
        }, {
            show: true,
            loading: false,
            label: stats.localization['trade_statistics_management_stop_button'],
            fn() {
                canDoAction('stop');
            },
        }, {
            show: !botShortInfo.value?.simulate,
            loading: false,
            label: stats.localization['trade_statistics_management_pause_button'],
            fn() {
                canDoAction('pause');
            },
        }, {
            show: true,
            loading: false,
            label: stats.localization['trade_statistics_management_edit_button'],
            fn() {
                router.push({ name: 'bots.edit', params: { id: route.params.id } });
            },
        }, {
            show: !botShortInfo.value?.simulate,
            loading: false,
            label: stats.localization['trade_statistics_management_cancelorders_button'],
            fn() {
                confirm.openConfirm({
                    disabledByChecked: false,
                    confirmLabel: stats.localization['trade_statistics__stop_bot_after_operation'],
                    declineMessage: stats.localization['trade_statistics_management_cancelorders_button'],
                    fn: $event => onCancelOrders($event),
                    type: 'confirm_check',
                });
            },
        },{
            show: !botShortInfo.value?.simulate,
            loading: false,
            label: stats.localization['trade_statistics_management_markcyclecanceled_button'],
            fn() {
                markLastAsCanceled.value = true;
            },
        }, {
            show: botShortInfo.value?.simulate,
            loading: loadings.clearCycles,
            label: stats.localization['trade_statistics_bots_table_actions_clear'],
            fn() {
                clearCycles(route.params.id);
            },
        }, {
            show: true,
            loading: loadings.clearCycles,
            label: stats.localization['trade_statistics_bots_table_mass_force_stop'],
            fn() {
                canDoAction('force_stop');
            },
        },
    ]);

    const dashProfit = computed(() => (
        {
            title: stats.localization['trade_statistics_total_profit'],
            data: stats.cyclesDataTable?.revenue_stats,
            ciclos: stats.cyclesDataTable?.revenue_stats?.cycles.completed,
            stats: !stats.cyclesDataTable?.revenue_stats ? [] : (Object.entries(stats.cyclesDataTable?.revenue_stats.coins).map(([key, value]) => [...value, key]))
                .sort(([ , , a], [ , , b]) => {
                    if (a < b)
                        return -1;
                    if (a > b)
                        return 1;
                    return;
                }),
        }),
    );

    const columns = computed(() => {
        if (stats.cyclesDataTable?.columns) {
            const arr = [...stats.cyclesDataTable.columns].filter(({ title }) => title);
            return arr;
        } else return [];
    });

    const columnsOrdersTable = computed(() => {
        if (stats.cyclesDataTable?.columns) {
            const arr = [...stats.ordersDataTable.columns].filter(({ title }) => title);
            return arr;
        } else return [];
    });

    watch(() => refs.lang, async () => {
        // await getData();
    });

    watch([
        () => stats.currentCycle,
        () => stats.forAllCycles,
    ], () => {
        if (stats.currentCycle) {
            resetOrdersFilters();
            ordersGet(true);
        }
    });

    const resetOrdersFilters = () => {
        ordersFilters.page = 1;
        ordersFilters.perPage = 50;
    };

    const getShortInfo = async (showLoading = true) => {
        if (showLoading)
            gl.showLoading = true;

        try {
            botShortInfo.value = ( await StatsService.getShortInfo(route.params.id) ).data;
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        };

        if (showLoading)
            gl.showLoading = false;
    };

    const changeFilter = ([ key, value ], update = false) => {
        const canUpdate = key === 'query' && !value && stats.cyclesDataTable.pager.query;

        if (key !== 'page') {
            _.set(filters, 'page', 1);
        }

        if (key === 'perPage') {
            LocalStorage.setItem('__filters_cycles_table', value);
        }

        _.set(filters, key, value !== null && value !== undefined ? value : [ -1 ]);
            
        if (update || canUpdate)
            cyclesGet();
    };

    const changeFilter2 = ([ key, value ], update = false) => {
        const canUpdate = key === 'query' && !value && stats.cyclesDataTable.pager.query;

        if (key !== 'page') {
            _.set(ordersFilters, 'page', 1);
        }

        _.set(ordersFilters, key, value !== null && value !== undefined ? value : [ -1 ]);
            
        if (update || canUpdate)
            ordersGet();
    };

    const changeOrdersFilters = ([ key, value ], update = true) => {
        if (key === 'perPage') {
            _.set(ordersFilters, 'page', 1);
        };

        _.set(ordersFilters, key, value !== null && value !== undefined ? value : [ -1 ]);
            
        if (update)
            ordersGet();
    };

    const canDoAction = async curAction => {
        gl.showLoading = true;

        confirmLabel.value = null;
        declineMessage.value = null;

        if (curAction === 'start') {
            try {
                const result = await BotsService.canDoAction({
                    id: route.params.id,
                    action: curAction,
                });

                if (result) {
                    if (result.data.confirm_label && result.data.decline_message) {
                        confirm.openConfirm({
                            confirmLabel: result.data.confirm_label,
                            declineMessage: result.data.decline_message,
                            fn: () => onGeneralConfirmClicked(curAction),
                            type: 'confirm_check',
                        });
                    } else {
                        confirm.openConfirm({
                            title: refs.localization.confirmations.bot[curAction],
                            fn: () => onGeneralConfirmClicked(curAction),
                        });
                    }

                    if (result?.postMessages) {
                        result.postMessages.forEach(el => {
                            notification[el.success ? 'success' : 'error']({
                                content: el.msg,
                                duration: 2500,
                                keepAliveOnHover: true,
                            });
                        });
                    }
                }
            } catch {
                notification.error({
                    content: t('errorMessage'),
                    duration: 2500,
                    keepAliveOnHover: true,
                });
            };
        }  else {
            confirm.openConfirm({
                title: refs.localization.confirmations.bot[curAction],
                fn: () => onGeneralConfirmClicked(curAction),
            });
        }

        gl.showLoading = false;
    };

    const onGeneralConfirmClicked = async curAction => {
        gl.showLoading = true;

        try {
            let result;

            if (curAction === 'delete') {
                result = await BotsService.delete({
                    ids: [route.params.id],
                });
            } else {
                result = await BotsService.action({
                    id: route.params.id,
                    action: curAction,
                });
            }
                
            if (result) {
                console.log('result', result);

                // show messages
                if (result.postMessages)
                    result.postMessages.forEach(el => {
                        gl.showNotification({
                            type: el.success ? 'success' : 'error',
                            msg: el.msg,
                        });
                    });

                // if (data.data.status) {
                //     return void emit('getData');
                // }
                await getAll(false);
            }
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        };

        gl.showLoading = false;
    };

    const updateDate = $event => {
        if ($event) 
            filters.filters.period = `${new Date($event[0]).toISOString().split('T')[0]} ${new Date($event[1]).toISOString().split('T')[0]}`;
    };

    const onCancelOrders = async (obj) => {
        gl.showLoading = true;

        try {
            let prepare;

            prepare = await StatsService.cancelOrders({
                id: route.params.id,
                stop_bot_after_operation: obj.genConfCheck,
            });
                
            if (prepare) {
                // show messages
                if (!prepare.data.status) {
                    prepare.data.errors.forEach(({ msg }) => {
                        notification.error({
                            content: msg,
                            duration: 2500,
                            keepAliveOnHover: true,
                        });
                    });
                        
                } else {
                    return void getAll();
                }
            }
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        };

        gl.showLoading = false; 
    };

    const doMarkLastAsCanceled = async () => {
        gl.showLoading = true;

        try {
            const prepare = await StatsService.markLastAsCanceled(route.params.id);

            // show messages
            prepare.postMessages.forEach(el => {
                notification[el.success ? 'success' : 'error']({
                    content: el.msg,
                    duration: 2500,
                    keepAliveOnHover: true,
                });
            });
            
            if (prepare.data.status) {
                markLastAsCanceled.value = false;
                await getShortInfo();
                cyclesGet();

                return;
            }
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        };

        gl.showLoading = false;
    };

    const cyclesGet = async (showLoading = true, resetOrdersDataTable = true) => {
        if (showLoading)
            gl.showLoading = true;
        loadings.table = true;

        if (resetOrdersDataTable)
            stats.ordersDataTable = null;

        try {
            const prepare = await StatsService.cyclesGet({
                pager: filters,
            });
            
            const i = prepare.data.columns.findIndex(({ name }) => name == 'current_orders_info');

            if (~i) {
                prepare.data.columns.splice(i, 0, {
                    isSortable: true,
                    name: 'p_n_l',
                    title: 'PNL',
                });
            }

            if (Array.isArray(prepare.data.records)) {
                prepare.data.records.forEach((item, i) => {
                    const price = item.prices;
                    item.bot_id = botShortInfo.value.id;
    
                    if (price == null || price.includes('NA')) {
                        return;
                    }
    
                    const [course, fixPrice, zeroPrice] = price.split('\n');
                    let fixDelta = null;
                    let zeroDelta = null;
                    if (!isLong.value) {
                        fixDelta = -Number(fixPrice) + Number(course);
                        zeroDelta = -Number(zeroPrice) + Number(course);
                    } else {
                        fixDelta = Number(fixPrice) - Number(course);
                        zeroDelta = Number(zeroPrice) - Number(course);
                    }
                    const fixPerc = ((fixDelta / Number(course)) * 100).toFixed(2);
                    const zeroPerc = ((zeroDelta / Number(course)) * 100).toFixed(2);
                    const newText = `${course}\n${fixPrice} (${fixPerc}%)\n${zeroPrice} (${zeroPerc}%)`;
                    prepare.data.records[i].prices_draft = newText;
                    item.p_n_l = (((zeroDelta / Number(course)) * 100).toFixed(2)) * -1;
                });
                
                if (stats.cyclesDataTable) {
                    Object.keys(prepare.data).forEach(key => {
                        if (!['stats', 'columns'].includes(key)) {
                            stats.cyclesDataTable[key] = prepare.data[key];
                        }
                    });
                } else {
                    stats.cyclesDataTable = prepare.data;
                }
    
                stats.cyclesDataTable.records.push({
                    type: 'footer',
                    ...stats.cyclesDataTable.totals,
                });
            }
        } catch (e) {
            console.log(e);
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        };

        if (showLoading)
            gl.showLoading = false;
    
        loadings.table = false;
            
    };

    const ordersGet = async (showLoading = false) => {
        if (showLoading)
            gl.showLoading = true;

        loadings.tableOrders = true;

        ordersFilters.filters.cycles = [ stats.forAllCycles ? -1 : stats.currentCycle];
            
        try {
            const prepare = await StatsService.ordersGet({
                pager: ordersFilters,
            });

            // console.log('prepare', prepare.data);
            // console.log('botShortInfo', botShortInfo.value);

            prepare.data.records
                .sort((a, b) => {
                    const f = a.cycle_id__order_id.split('/')[1];
                    const s = b.cycle_id__order_id.split('/')[1];

                    return s - f;
                })
                .sort((a, b) => {
                    const f = a.order_type == 'buy' ? 1 : 0;
                    const s = b.order_type == 'buy' ? 1 : 0;

                    return botShortInfo.value.algo == 'long' ? f - s : s - f;
                });

            if (stats.ordersDataTable) {
                Object.keys(prepare.data).forEach(key => {
                    if (!['columns'].includes(key)) {
                        stats.ordersDataTable[key] = prepare.data[key];
                    }
                });
            } else {
                stats.ordersDataTable = prepare.data;
            }

            setTimeout(() => {
                const el = document.querySelector('#cycles-orders-table');
                if (el) el.scrollIntoView({ block: 'start', behavior: 'smooth' });
            });
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        };

        loadings.tableOrders = false;

        if (showLoading)
            gl.showLoading = false;
    };

    const getData = async () => {
        try {
            if (!stats.refs)
                stats.refs = await StatsService.getRefs();
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        };
    };

    const currency = icon => refs.currencies.find(el => el.id === icon);
    const exchange = exchange => refs.exchanges.find(el => el.id === exchange);

    const getAll = async (showLoading = true) => {
        if (showLoading)
            gl.showLoading = true;

        loadings.table = true;

        await Promise.allSettled([
            getData(),
            await getShortInfo(false),
            cyclesGet(),
        ]);

        stats.currentCycle = null;

        if(showLoading)
            gl.showLoading = false;

        loadings.table = false;
    };

    const totalUpdate = async () => {
        totalUpdateLoading.value = true;

        await Promise.allSettled([
            getData(),
            await getShortInfo(false),
            cyclesGet(false, false),
        ]);

        totalUpdateLoading.value = false;
    };

    const sortColumn = $event => {
        _.set(
            filters,
            'sort_column',
            $event.columnKey !== undefined && $event.order !== undefined
                ? $event.columnKey
                : '');

        _.set(
            filters,
            'sort_dir',
            $event.order !== undefined
                ? $event.order
                : '');

        cyclesGet();
    };

    const downloadData = async format => {
        gl.showLoading = true;

        try {
            const { redirectTo } = ( await StatsService.cyclesGet({
                format,
                pager: filters,
            }) ).data;

            window.location.href = redirectTo;
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        };

        gl.showLoading = false;
    };

    const downloadData2 = async format => {
        gl.showLoading = true;

        try {
            const { redirectTo } = ( await StatsService.ordersGet({
                format,
                pager: ordersFilters,
            }) ).data;

            window.location.href = redirectTo;
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        };

        gl.showLoading = false;
    };

    const doSomething = ([ action, value ]) => {
        const fns = {
            setCurrentCycle: () => {
                stats.currentCycle = value;
            },
        };

        fns[action]();
    };

    const clearCycles = async id => {
        loadings.clearCycles = true;

        try {
            const result = await StatsService.clearCycles(id);

            // show messages
            result.postMessages.forEach(el => {
                notification[el.success ? 'success' : 'error']({
                    content: el.msg,
                    duration: 2500,
                    keepAliveOnHover: true,
                });
            });
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        };

        loadings.clearCycles = false;
    };

    const closeAtMarketConfirm = () => {
        confirm.openConfirm({
            disabledByChecked: false,
            declineMessage: refs.localization.confirmations.bot?.close_at_market || 'Закрыть цикл по рынку?',
            confirmLabel: stats.localization['trade_statistics__stop_bot_after_operation'],
            type: 'confirm_check',
            fn: $event => closeAtMarket($event),
        });
    };

    const closeAtMarket = async obj => {
        loadings.closeAtMarket = true;

        const payload = {
            _formPath: 'closeAtMarket',
            id: route.params.id,
            market: true,
            stop_bot_after_fix_completed: obj.genConfCheck };

        try {
            const prepare = await StatsService.resetFixOrder(payload);

            if (prepare) {
            // show messages
                if (prepare.postMessages)
                    prepare.postMessages.forEach(el => {
                        gl.showNotification({
                            type: el.success ? 'success' : 'error',
                            msg: el.msg,
                        });
                    });
              
                // show messages
                if (prepare.data.errors)
                    prepare.data.errors.forEach(el => {
                        gl.showNotification({
                            type: 'error',
                            msg: el.msg,
                        });
                    });
            }

            if (prepare.data.status) {
                await getAll();
            } else {
                loadings.closeAtMarket = false;
            }
          
        } catch (error) {
            console.log(error);

            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        };

        loadings.closeAtMarket = false;
    };

    onMounted(async () => {
        await getAll();

        init.value = true;
    });

    return {
        gl,
        env,
        init,
        refs,
        stats,
        isLong,
        buttons,
        periods,
        filters,
        columns,
        loadings,
        buyedVolume,
        buyedPrice,
        refFilters,
        dashProfit,
        botShortInfo,
        confirmLabel,
        genConfCheck,
        averagePrice,
        reSetFixOrder,
        buyedPriceAll,
        declineMessage,
        ordersFilters,
        filtersChanged,
        buyedVolumeLabel,
        totalUpdateLoading,
        markLastAsCanceled,
        columnsOrdersTable,
        statsOrdersDataTablePair,
        router,
        t,
        getAll,
        currency,
        exchange,
        cyclesGet,
        ordersGet,
        updateDate,
        sortColumn,
        totalUpdate,
        doSomething,
        downloadData,
        resetFilters,
        changeFilter,
        getShortInfo,
        onCancelOrders,
        changeFilter2,
        downloadData2,
        closeAtMarketConfirm,
        changeOrdersFilters,
        doMarkLastAsCanceled,
        onGeneralConfirmClicked,
    };
}