<template>
<base-modal-desktop
    :label="data.localization?.trading_results || 'Торговые результаты'"
    :show="show"
    :loading="data.loadings.modal"
    @update:show="$emit('update:show', $event)"
    width="650px">
    <template #default>
        <template v-if="data.isModal">
            <template v-if="data.mode != 'date'">
                <n-alert class="rounded-md" type="success" size="small">
                    <div class="notes text-sm">{{ data.localization?.sharing_info || 'Делитесь своими торговыми результатми с друзьями в социальных сетях' }}</div>
                </n-alert>
            </template>
        </template>

        <template v-if="data.uuid">
            <div
                class="mt-4 flex flex-row items-center justify-between font-normal bg-gray-100 dark:bg-darkbg text-main p-4 rounded-md text-xs cursor-pointer"
                @click="data.copy(data.linkHTMLResult)">
                {{ data.linkHTMLResult }}
                <n-icon class="ml-4" size="25">
                    <Copy16Regular />
                </n-icon>
            </div>
            <n-divider class="my-3" />

            <section class="relative">
                <img :src="data.linkImageResult" class="rounded-lg w-full" />

                <a class="absolute z-50 right-3 top-3 text-black rounded-lg flex items-center bg-white py-1 px-3" :href="data.linkImageResult" download>
                    {{ data.localization?.download_image || 'Скачать картинку' }}
                    <n-icon class="ml-2" size="18">
                        <ArrowDownload16Filled />
                    </n-icon>
                </a>
            </section>
            
            <section class="flex items-center justify-center mt-8 mb-6">
                <n-divider class="mr-3 my-0" />

                <template v-for="(item, i) in data.socialLinks" :key="i">
                    <n-button
                        secondary
                        size="large"
                        :class="item.buttonClass"
                        @click="item.fn">
                        <component :is="item.icon" :class="item.class" fill="#1A6DE0" />
                    </n-button>
                    <n-divider v-if="i < data.socialLinks.length - 1" class="mx-1 my-0 w-[40px]" />
                </template>

                <n-divider class="ml-3 my-0" />
            </section>
        </template>
    </template>
</base-modal-desktop>
</template>

<script>
// icons
import {
    Copy16Regular,
    ArrowDownload16Filled } from '@vicons/fluent';

// UI
import {
    NIcon,
    NAlert,
    NButton,
    NDivider } from 'naive-ui';

export default {
    name: 'rb-trading-results-modal-desktop',
    props: {
        data: {
            type: Object,
            required: true,
        },
        show: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        NIcon,
        NAlert,
        NDivider,
        NButton,
        Copy16Regular,
        ArrowDownload16Filled,
    },
    setup(props, context) {
        // 
    },
};
</script>