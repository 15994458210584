// vue
import { ref, reactive, computed, watch, onMounted } from 'vue';

// store
import { useGl } from '@store/ts/gl';
import { useRefs } from '@store/ts/refs';
import { useStats } from '@store/stats';
import { useEnv } from '@store/ts/env';

// i18n
import { useI18n } from 'vue-i18n';

// download

// socials
import FB from '@components/icons/social/fb.vue';
import X from '@components/icons/social/x.vue';
import TG from '@components/icons/social/tg.vue';
import VK from '@components/icons/social/vk.vue';

// services
import ProfileService from '@services/profileService';

export default function (props, context) {
    // store
    const gl = useGl();
    const refs = useRefs();
    const stats = useStats();
    const env = useEnv();

    // vars
    const dataPeriod = reactive({
        year: null,
        month: null,
    });
    const uuid = ref();
    const showModal = ref(false);
    const loadings = reactive({
        modal: false,
        generate: false,
    });

    // 18n
    const { t } = useI18n();

    watch(() => dataPeriod.year, () => {
        dataPeriod.month = null;
    });

    watch(() => props.show, v => {
        if (v && props.isModal) {
            if (['bot', 'cycle'].includes(props.mode)) {
                addGetUserStats(['modal']);
            }
        }
    });

    watch(showModal, v => {
        if (!v) {
            uuid.value = null;
            dataPeriod.year = null;
        }
    });

    const generateLinkDisabled = computed(() => dataPeriod.year == null || dataPeriod.year == -1);
    const linkImageResult = computed(() => `https://app.revenuebot.io/public/api/tools/stats/getUserStatsImg/${uuid.value}.png?dl=1`);
    const linkHTMLResult = computed(() => `https://app.revenuebot.io/public/api/tools/stats/getUserStatsHtml/${uuid.value}.html`);
    const mode = computed(() => dataPeriod.month !== null ? 'per_month'  : 'per_year');
    const monthForDate = computed(() => String(dataPeriod.month).length == 1 ? '0' + dataPeriod.month : dataPeriod.month);
    const modalProps = computed(() => ({
        dataPeriod,
        isModal: props.isModal,
        mode: props.mode,
        uuid: uuid.value,
        loadings,
        localization: stats.localization,
        linkHTMLResult: linkHTMLResult.value,
        linkImageResult: linkImageResult.value,
        socialLinks: socialLinks.value,
        monthOptions: monthOptions.value,
        yarsOptions: yarsOptions.value,
        generateLinkDisabled: generateLinkDisabled.value,
        copy,
        addGetUserStats,
    }));

    const sharingSocialLinks = computed(() => {
        const list = refs.localization.sharing_social_links;

        const date = new Date(mode.value == 'per_month' ? `${dataPeriod.year}-${monthForDate.value}` : dataPeriod.year);
        const monthName = date.toLocaleString(env.language, { month: 'long' });

        Object.entries(list).forEach(([ key, value ]) => {
            const title = value?.title ? `title=${(value.title[mode.value]).replace('{{#month}}', monthName[0].toUpperCase() + monthName.slice(1)).replace('{{#year}}', dataPeriod.year)}&` : '';
            const hashtags = value?.hashtags ? `hashtags=${value.hashtags}&` : '';
            const description = value?.description ? `description=${value.description}&` : '';

            if (key == 'fb') {
                value.link = `https://www.facebook.com/sharer/sharer.php?u=${linkHTMLResult.value}`;
            } else if (key == 'x') {
                value.link = `https://x.com/intent/tweet?${title}${hashtags}url=${linkHTMLResult.value}`;
            } else if (key == 'tg') {
                value.link = `https://t.me/share/url?${title}url=${linkHTMLResult.value}`;
            } else if (key == 'vk') {
                value.link = `https://vk.com/share.php?${title}${description}url=${linkHTMLResult.value}&image=${linkHTMLResult.value}`;
            }
        });

        // console.log('list', list);

        return list;
    });

    const socialLinks = computed(() => {
        return [
            {
                icon: FB,
                class: 'w-5',
                buttonClass: 'rounded-md px-3 flex items-center',
                fn: () => window.open(sharingSocialLinks.value['fb'].link, '_blank'),
            }, {
                icon: X,
                class: 'w-5',
                buttonClass: 'rounded-md px-3 flex items-center',
                fn: () => window.open(sharingSocialLinks.value['x'].link, '_blank'),
            }, {
                icon: TG,
                class: 'w-7',
                buttonClass: 'rounded-md px-2 flex items-center',
                fn: () => window.open(sharingSocialLinks.value['tg'].link, '_blank'),
            }, {
                icon: VK,
                class: 'w-8',
                buttonClass: 'rounded-md px-2 flex items-center',
                fn: () => window.open(sharingSocialLinks.value['vk'].link, '_blank'),
            },
        ];
    });

    const yarsOptions = computed(() => {
        const result = [];
        let today = new Date();
        let year = today.getFullYear();

        for (let i = 2021; i <= year; i++) {
            result.push({
                value: i,
                label: i,
            });
        }

        return result;
    });

    const monthOptions = computed(() => {
        const result = [];
        let today = new Date();
        let month = today.getMonth();
        const formatter = new Intl.DateTimeFormat(refs.lang, { month: 'long' });

        if (dataPeriod.year >= 2025) {
            for (let curM = 0; curM <= month; curM++) {
                const month = formatter.format(new Date(dataPeriod.year, curM));
                    
                result.push({
                    value: curM + 1,
                    label: month[0].toUpperCase() + month.slice(1),
                });
            }
        }

        return result;
    });

    const addGetUserStats = async (lds = ['generate'], mode = props.mode) => {
        lds.forEach(el => loadings[el] = true);

        try {
            let payload;

            if (mode == 'date') {
                payload = {
                    type: dataPeriod.month !== null ? 'month'  : 'year',
                    year: dataPeriod.year,
                    ...(dataPeriod.month !== null ? { 'month': dataPeriod.month  } : null),
                };
            } else if (mode == 'bot') {
                payload = {
                    sub_type: mode,
                    bot_id: props.tradingResultData.botId,
                };
            } else if (mode == 'cycle') {
                payload = {
                    sub_type: mode,
                    bot_id: props.tradingResultData.botId,
                    cycle_id: props.tradingResultData.cycleId,
                };
            }

            const result = await ProfileService.addGetUserStats(payload);

            uuid.value = result?.data?.uuid;
        
            // open modal
            showModal.value = true;
        } catch (e) {
            console.log(e);
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        };

        lds.forEach(el => loadings[el] = false);
    };

    const copy = s => {
        navigator.clipboard.writeText(s);
        gl.showNotification({
            type: 'success',
            msg: t('copied'),
        });
    };

    const downloadImg = url => {
        window.open(url, '_self');
        // download(url);
    };

    const setDataPeriod = $event => {
        dataPeriod[$event[0]] = $event[1];
    };

    return {
        gl,
        refs,
        stats,
        uuid,
        loadings,
        showModal,
        dataPeriod,
        modalProps,
        yarsOptions,
        socialLinks,
        monthOptions,
        linkImageResult,
        linkHTMLResult,
        generateLinkDisabled,
        copy,
        downloadImg,
        setDataPeriod,
        addGetUserStats,
    };

}