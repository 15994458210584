<template>
<svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" fill="none"><title/><g data-name="vk vkontakte media social" id="vk_vkontakte_media_social"><path d="M28.89,22a30.07,30.07,0,0,0-4.13-5.15.2.2,0,0,1,0-.25,40.66,40.66,0,0,0,3.55-5.81,1.9,1.9,0,0,0-.08-1.86A1.81,1.81,0,0,0,26.65,8h-3a2,2,0,0,0-1.79,1.19,35,35,0,0,1-3.12,5.51V9.8A1.79,1.79,0,0,0,16.94,8H12.56a1.4,1.4,0,0,0-1.12,2.21l.4.56a1.84,1.84,0,0,1,.33,1.05v3.84A26.11,26.11,0,0,1,9.05,9.35,2,2,0,0,0,7.16,8H4.71a1.73,1.73,0,0,0-1.66,2.14c1.35,5.73,4.18,10.48,7.77,13a1,1,0,0,0,1.39-.23,1,1,0,0,0-.23-1.4C8.84,19.31,6.34,15.12,5.07,10l2.1,0a26.12,26.12,0,0,0,4.1,7.75,1.6,1.6,0,0,0,1.8.52,1.64,1.64,0,0,0,1.1-1.57V11.82A3.78,3.78,0,0,0,13.71,10h3v5.43A1.77,1.77,0,0,0,18,17.15a1.74,1.74,0,0,0,2-.69A36.87,36.87,0,0,0,23.62,10h2.8a39.81,39.81,0,0,1-3.29,5.37,2.17,2.17,0,0,0,.2,2.83A32.08,32.08,0,0,1,27.25,23H23.9a14,14,0,0,0-4.07-4.31,1.64,1.64,0,0,0-1.73-.13,1.69,1.69,0,0,0-.92,1.52v2.38a.53.53,0,0,1-.5.55h-.86a1,1,0,0,0,0,2h.86a2.52,2.52,0,0,0,2.5-2.55V20.69a11.78,11.78,0,0,1,3,3.32,2,2,0,0,0,1.69,1h3.38a1.92,1.92,0,0,0,1.69-1A2,2,0,0,0,28.89,22Z"/></g></svg>
</template>

<script>
export default {
    setup() {
    // 
    },
};
</script>
