<template>
<section>
    <div class="flex justify-between items-center">
        <n-button
            circle
            tertiary
            @click="update"
            :disabled="loading">
            <n-icon size="25"><ArrowSyncCircle16Regular /></n-icon>
        </n-button>

        <div class="flex">
            <n-button
                size="medium"
                tertiary
                class="rounded-md px-2"
                :disabled="loading"
                @click="showColumnsSettings = true">
                    <n-icon size="25"><ColumnEdit20Filled /></n-icon>
            </n-button>
            <n-button
                v-if="showSearch"
                size="medium"
                tertiary
                class="rounded-md px-2 ml-2"
                :disabled="loading"
                @click="showSearchLocal = !showSearchLocal">
                <n-icon
                  size="20"
                  :class="showSearchLocal ? 'text-main' : ''"><Search12Filled /></n-icon>
            </n-button>
            <!-- <filters
                class="ml-2"
                :filters="filters"
                :refFilters="refFilters"
                :filtersChanged="filtersChanged"
                @getData="$emit('getData')"
                @updateDate="$emit('updateDate', $event)"
                @changeFilter="$emit('changeFilter', $event)"
                @reset-filters="$emit('reset-filters')"/> -->
            <n-button
                v-if="!zoomButton"
                circle
                secondary
                size="medium"
                type="default"
                class="ml-2"
                @click="$emit('show-modal', false)">
                <template #icon>
                    <n-icon class="text-gray-600 dark:text-white" size="16"><Dismiss16Filled /></n-icon>
                </template>
            </n-button>
        </div>
        
        
        
        <!--
        <div class="flex-grow mx-2">
            <rb-input
                v-if="showSearchLocal"
                clearable
                type="text"
                size="large"
                :value="filters.query"
                :placeholder="placeholder"
                @update:value="$emit('changeFilter', [ 'query', $event ])"
                @keyup.enter="$emit('getData')"
                @keyup.esc="$emit('changeFilter', [ 'query', '' ])" />
        </div>
        <slot name="afterSearch" ></slot>

        <div class="flex">
            <filters
                :filters="filters"
                :refFilters="refFilters"
                @getData="$emit('getData')"
                @updateDate="$emit('updateDate', $event)"
                @changeFilter="$emit('changeFilter', $event)"/>
        </div> -->
    </div>
    <div v-if="showSearchLocal" class="flex mt-2">
        <rb-input
            clearable
            type="text"
            size="medium"
            :disabled="loading"
            :value="filters.query"
            :placeholder="placeholder"
            @update:value="$emit('changeFilter', [ 'query', $event ])" />
        <n-button
            size="medium"
            tertiary
            class="rounded-md px-4 ml-2"
            :disabled="loading"
            @click="$emit('getData')">
            {{  refs.localization.grids.search_label }}
        </n-button>
    </div>
    
    <n-drawer
        v-model:show="showColumnsSettings"
        width="100%"
        display-directive="show"
        class="bg-gray-100 dark:bg-darkbg">
        <n-drawer-content body-content-style="padding: 0">
            <div class="flex flex-col h-full">
                <div class="relative w-full bg-gray-100 dark:bg-darkbg text-sm text-center text-gray-600 dark:text-white/75 py-4">
                    <n-icon
                        size="20"
                        class="text-gray-600 dark:text-white/75 absolute top-1/2 left-2 -translate-y-1/2"
                        @click="showColumnsSettings = false">
                        <ArrowLeft12Regular />
                    </n-icon>
                    {{ refs.localization.grids.tvs.modal_title }}
                </div>
                <div class="pb-4 flex-grow overflow-y-scroll overscroll-none scrollbar-hide">
                    <div
                        v-for="(item, i) in columnsOptions"
                        :key="i"
                        class="w-full flex justify-between items-center pl-6 pr-3 py-2 rounded-none dark:odd:bg-[#242424] odd:bg-gray-50"
                        @click="setCheck(columns.find(({ name }) => name === item.name))">
                        {{ item.label }}
                        <n-icon v-if="!item.isHidden" size="20" class="text-main">
                            <Checkmark12Filled />
                        </n-icon>
                    </div>
                </div>
            </div>
        </n-drawer-content>
    </n-drawer>
</section>
</template>

<script>
// vue
import { h, ref, reactive, computed, watch, onMounted } from 'vue';

// store
import { useGl } from '@store/ts/gl';
import { useRefs } from '@store/ts/refs';
import { useMarketplace } from '@store/ts/marketplace';

// naive-ui
import {
    NCard,
    NIcon,
    NModal,
    NInput,
    NButton,
    NDrawer,
    NPopover,
    NSkeleton,
    NDropdown,
    NCheckbox,
    NDrawerContent } from 'naive-ui';

// i18n
import { useI18n } from 'vue-i18n';

// components
import RbInput from '@components/rb-input/mobile.vue';
import Filters from '../filters/mobile.vue';
import Progress from '@components/base/progress.vue';
import GridNav from '@components/base/grid-nav.vue';
import RbCheckbox from '@components/rb-checkbox';
import RbCheckboxList from '@components/rb-checkbox-list/mobile.vue';

// icons
import {
    Filter20Filled,
    Search12Filled,
    Dismiss16Filled,
    ColumnEdit20Filled,
    CaretDown12Filled,
    CaretDown24Filled,
    Checkmark12Filled,
    ArrowLeft12Regular,
    ArrowExpand20Filled,
    ArrowSyncCircle16Regular } from '@vicons/fluent';

// services
import LocalStorage from '@services/ts/localStorage';

export default {
    name: 'table-header-mobile',
    emits: ['setPerPage', 'enter', 'update:search'],
    props: {
        label: String,
        loading: Boolean,
        searchPlaceholder: String,
        zoomButton: {
            type: Boolean,
            default: false,
        },
        filters: {
            type: Object,
            required: true,
        },
        refFilters: {
            type: [ Object, Array ],
        },
        columns: {
            type: Array,
            required: true,
        },
        columnsStorageName: {
            type: String,
            required: true,
        },
        showSearch: {
            type: Boolean,
            default: true,
        },
        refresh: {
            type: Boolean,
            default: true,
        },
        filtersChanged: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        NCard,
        NIcon,
        NModal,
        NInput,
        NDrawer,
        RbInput,
        NButton,
        GridNav,
        Filters,
        Progress,
        NPopover,
        NSkeleton,
        NCheckbox,
        NDropdown,
        RbCheckbox,
        Search12Filled,
        NDrawerContent,
        RbCheckboxList,
        Filter20Filled,
        Dismiss16Filled,
        Checkmark12Filled,
        CaretDown12Filled,
        CaretDown24Filled,
        ColumnEdit20Filled,
        ArrowExpand20Filled,
        ArrowLeft12Regular,
        ArrowSyncCircle16Regular,
    },
    setup(props, { emit, slots }) {
        // store
        const gl = useGl();
        const refs = useRefs();
        const marketplace = useMarketplace();

        // i18n
        const { t } = useI18n();

        // vars
        const progress = ref();
        const showSearchLocal = ref(false);
        const showColumnsSettings = ref(false);
        const checkedColumns = ref([]);
        const showModal = ref(false);
        const autoUpdate = ref(false);
        const showDropdownRef = ref(false);

        const localLoading = computed(() => gl.loading || props.loading);
        const placeholder = computed(() => props.searchPlaceholder || refs.localization.grids.search_label);
        const columnsOptions = computed(() => {
            return props.columns.map(el => ({
                checked: el.isHidden,
                label: el.title,
                ...el,
            })).filter(({ name }) => !['checked', 'actions'].includes(name));
        });

        watch(() => props.columns, () => {
            columnsPrepare();
        });

        // watch(() => gl.canAutoUpdate, v => {
        //     if (autoUpdate.value) {
        //         !v
        //             ? progress.value.stop()
        //             : progress.value.start();
        //     }
        // });

        watch(() => props.loading, v => {
            if (v && autoUpdate.value)
                progress.value.stop();
            else if (!v && autoUpdate.value)
                progress.value.start();
        });

        // watch([...props.columns.map((_, i) => () => props.columns[i].isHidden)], () => {
        //     // emit('changeDataColumn', props.columns);
        // });

        const doneTimer = () => {
            emit('getData');
        };

        const update = () => {
            if (autoUpdate.value) progress.value.stop();
            emit('getData');

            // gl.loading = true;
            // if (autoUpdate.value) progress.value.stop();

            // await forceUpdate();

            // if (autoUpdate.value) progress.value.start();
            // gl.loading = false;
        };

        const setCheck = column => {
            column.isHidden = !column.isHidden;

            saveStorageColumns();
        };

        const saveStorageColumns = () => {
            LocalStorage.setItem(props.columnsStorageName, JSON.stringify(props.columns));
        };

        const renderDropdownLabel = option => {
            return h(RbCheckbox, {
                label: option.label,
                checked: !option.isHidden,
                class: 'w-full h-full items-center',
                onUpdateChecked: () => setCheck(props.columns.find(({ name }) => name === option.name)),
            });
        };

        const columnsPrepare = () => {
            const localColumns = JSON.parse(LocalStorage.getItem(props.columnsStorageName));

            if (localColumns) {
                localColumns.forEach(({ name, isHidden }) => {
                    const el = props.columns.find(({ name: id }) => id === name);
                    if (el)
                        el.isHidden = isHidden;
                });
            };
        };

        onMounted(() => {
            columnsPrepare();
        });

        return {
            gl,
            refs,
            slots,
            progress,
            showModal,
            autoUpdate,
            marketplace,
            placeholder,
            localLoading,
            columnsOptions,
            showSearchLocal,
            showDropdownRef,
            showColumnsSettings,
            t,
            update,
            setCheck,
            doneTimer,
            checkedColumns,
            renderDropdownLabel,
        };
    },
};
</script>

<style lang="scss" scoped>
</style>