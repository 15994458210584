<template>
<base-drawer-mobile
    :show="show"
    :loading="data.loadings.modal"
    @update:show="$emit('update:show', $event)">
    <template #default>
        <section>
            <n-alert class="rounded-md" type="success" size="small">
                <div class="notes text-xs">{{ data.localization?.sharing_info || 'Делитесь своими торговыми результатми с друзьями в социальных сетях' }}</div>
            </n-alert>
            <template v-if="data.mode == 'date'">
                <section class="mt-4 flex">
                    <rb-select
                        class="w-6/12 pr-1"
                        :options="data.yarsOptions"
                        :placeholder="data.localization?.year || 'Год'"
                        :value="data.dataPeriod.year"
                        @update:value="$emit('setDataPeriod', ['year', $event])" />

                    <rb-select
                        class="w-6/12 pl-1"
                        :options="data.monthOptions"
                        :disabled="!data.monthOptions.length"
                        :placeholder="data.localization?.month || 'Месяц'"
                        :value="data.dataPeriod.month"
                        @update:value="$emit('setDataPeriod', ['month', $event])" />
                </section>

                <n-button
                    strong
                    secondary
                    size="large"
                    class="rounded-md mt-2 w-full"
                    :loading="data.loadings.generate"
                    :disabled="data.generateLinkDisabled || data.loadings.generate"
                    @click="data.addGetUserStats()">
                    {{ data.localization?.generate_link || 'Сгенерировать ссылку' }}
                </n-button>
            </template>

            <template v-if="data.uuid">
                <div
                    class="mt-4 flex flex-row items-center justify-between font-normal bg-white dark:bg-darkCardBg text-main p-4 rounded-md text-xs cursor-pointer"
                    @click="data.copy(data.linkHTMLResult)">
                    {{ data.linkHTMLResult }}
                    <n-icon class="ml-4" size="25">
                        <Copy16Regular />
                    </n-icon>
                </div>
                <n-divider class="my-3" />

                <section class="relative">
                    <img :src="data.linkImageResult" class="rounded-lg w-full" />

                    <a class="absolute z-50 right-3 top-3 text-black rounded-lg flex items-center bg-white py-1 px-3" :href="data.linkImageResult" download>
                        {{ data.localization?.download_image || 'Скачать картинку' }}
                        <n-icon class="ml-2" size="18">
                            <ArrowDownload16Filled />
                        </n-icon>
                    </a>
                </section>

                <section class="flex items-center justify-center mt-8 mb-6">
                    <n-divider class="mr-3 my-0" />

                    <template v-for="(item, i) in data.socialLinks" :key="i">
                        <n-button
                            secondary
                            size="large"
                            :class="item.buttonClass"
                            @click="item.fn">
                            <component :is="item.icon" :class="item.class" fill="#1A6DE0" />
                        </n-button>
                        <n-divider v-if="i < data.socialLinks.length - 1" class="mx-1 my-0 w-[40px]" />
                    </template>

                    <n-divider class="ml-3 my-0" />
                </section>
            </template>
        </section>
    </template>
</base-drawer-mobile>
</template>

<script>
// icons
import {
    Copy16Regular,
    ArrowDownload16Filled } from '@vicons/fluent';

// UI
import {
    NAlert,
    NIcon,
    NButton,
    NDivider } from 'naive-ui';

export default {
    name: 'rb-trading-results-modal-desktop',
    props: {
        data: {
            type: Object,
            required: true,
        },
        show: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        NIcon,
        NAlert,
        NDivider,
        NButton,
        Copy16Regular,
        ArrowDownload16Filled,
    },
    setup(props, context) {
        // 
    },
};
</script>