<template>
<n-progress
    type="line"
    :color="gl.mainColor"
    :height="4"
    :percentage="percentage"
    :show-indicator="false" />
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { NProgress } from 'naive-ui';

// store
import { useGl } from '@store/ts/gl';

export default {
    name: 'Progress',
    props: {
        time: {
            type: Number,
            default: 30000,
        },
    },
    components: {
        NProgress,
    },
    setup(props, { emit }) {
        // store
        const gl = useGl();

        const percentage = ref(0);

        const timer = ref();

        const start = () => {
            const period = props.time / 100 / 4;

            timer.value = setInterval(() => {
                percentage.value += 0.25;

                if (percentage.value >= 100) {
                    clearInterval(timer.value);
                    
                    setTimeout(() => {
                        emit('done');
                    }, 200);
                }
              
            }, period);
        };

        const stop = () => {
            clearInterval(timer.value);
            percentage.value = 0;
        };

        onMounted(() => {
            start();
        });

        onBeforeUnmount(() => {
            stop();
        });

        return {
            gl,
            stop,
            start,
            percentage,
        };
    },
};
</script>