<template>
<section v-if="revenueStats" class="flex items-stretch w-full pt-1">
    <div class="w-9/12 pr-2">
        <n-card
            size="small"
            :bordered="false"
            class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg h-full"
            content-style="padding: 0;"
            :segmented="{
                content: true,
                footer: 'soft'
            }">
            <template #header>
                <div v-if="dashProfit.title" class="flex justify-center text-sm text-gray-600 dark:text-white/75 font-semibold">
                    {{ dashProfit.title }}
                </div>
            </template>
            <div class="flex flex-col" :class="revenueStats.coins.length > 6 ? 'h-[306px]' : ''">
                <n-scrollbar class="h-[75%]">
                    <div
                        v-for="(stat, coin) in revenueStats.coins"
                        :key="coin"
                        class="w-full dark:odd:bg-[#242424] odd:bg-gray-50">
                        <div class="py-1 flex flex-row justify-start items-center overflow-x-hidden relative">
                            <div class="w-4/12 flex items-center">
                                <rb-coin class="mx-2 w-6" :coin="currency(coin)" />
                                <div class="text-sm font-semibold uppercase">{{ coin }}</div>
                            </div>
                            
                            <div class="text-sm font-semibold" :class="stat[0] > 0 ? 'text-green-600' : 'text-red-500'">{{ stat[0] }}</div>

                            <div
                                v-if="coin !== env.profitSymbol"
                                class="flex flex-row opacity-60 hover:opacity-100 items-center text-white justify-start absolute w-4/5 h-full bg-main right-[-72%] rounded-l-xl hover:right-0 transition-all cursor-pointer">
                                <n-icon class="absolute left-2 top-1/2 -translate-y-1/2">
                                    <ChevronLeft24Filled />
                                </n-icon>

                                <div class="w-3/12 flex items-center ml-6">
                                    <rb-coin class="mx-2 w-6" :coin="currency(env.profitSymbol)" />
                                    <div class="text-sm font-semibold uppercase">{{ env.profitSymbol }}</div>
                                </div>
                                
                                <div class="text-sm font-semibold">{{ stat[1] }}</div>
                            </div>
                        </div>
                    </div>
                </n-scrollbar>
                <div class="h-[25%] flex flex-col justify-center items-center py-4">
                    <div class="text-sm font-semibold">{{ revenueStats?.btc }} {{ env.profitSymbol.toUpperCase() }} / {{ revenueStats?.usdt || '---'}} USDT</div>

                    <div class="text-sm">
                        ( {{ revenueStats.cycles?.completed }} {{ stats.localization['dashboard_profit_total_cycles_completed'] }} )
                    </div>
                </div>
            </div>
        </n-card>
    </div>
    <div class="w-3/12 pl-2">
        <rb-trading-results v-if="init" class="ml-2 h-full" />
    </div>
</section>

<super-table
    v-if="init"
    showSearch
    class="mt-4"
    :filters="filters"
    :actions="actions"
    :refFilters="refFilters"
    :pager="currentTable.pager"
    :columns="columns"
    :loading="loadings.table"
    :records="currentTable.records"
    :filtersChanged="filtersChanged"
    columnsStorageName="__columns_stats"
    type="stats"
    @getData="botsGet"
    @doSomething="doSomething"
    @reset-filters="resetFilters"
    @applyFilters="changeFilter">
    <template #tableTitle>
        <div class="text-base text-left mb-2 text-gray-600 dark:text-white/75">
            {{ stats.localization['trade_statistics_available_bots_title'] }}
        </div>
    </template>
    <template #stats>
        <n-card
            size="small"
            :bordered="false"
            class="shadow bg-white dark:bg-darkCardBg rounded-lg my-3"
            :segmented="{
                content: true,
                footer: 'soft'
            }">
            <section class="text-md text-gray-600 dark:text-white/75">
                <div>
                    <span>{{ stats.localization['trade_statistics_bots_total'] }}</span>
                    <n-tag round size="small" :bordered="false" type="warning" class="ml-2">
                        <span class="text-yellow-500 font-semibold">{{ currentTable.stats.total }}</span>
                    </n-tag>
                    
                    <span class="ml-4">{{ stats.localization['trade_statistics_bots_running'] }}</span>
                    <n-tag round size="small" :bordered="false" type="success" class="ml-2">
                        <span class="text-green-500 font-semibold">{{ currentTable.stats.running }}</span>
                    </n-tag>
                    
                    <span class="ml-4">{{ stats.localization['trade_statistics_bots_stopped'] }}</span>
                    <n-tag round size="small" :bordered="false" class="ml-2">
                        <span class="font-semibold">{{ currentTable.stats.stopped }}</span>
                    </n-tag>
                    
                    <span class="ml-4">{{ stats.localization['trade_statistics_bots_error'] }}</span>
                    <n-tag round size="small" :bordered="false" type="error" class="ml-2">
                        <span class="text-red-400 font-semibold">{{ currentTable.stats.error }}</span>
                    </n-tag>
                </div>
                <n-divider class="my-4" />
                <section>
                    <div class="flex">
                        <rb-radio-group
                            light
                            v-model:value="sumWallets"
                            :options="optionsSumWallets" />

                        <rb-input
                            class="w-[120px] ml-4"
                            type="percent"
                            v-model:value="critPers" />
                        <n-button
                            strong
                            secondary
                            class="rounded-md ml-2"
                            @click="calculatePercentDeviation">
                            {{ stats.localization?.calculate_percent_deviation || 'Посчитать % отклонения' }}
                        </n-button>
                        <section class="flex items-center ml-3">
                        <n-popover
                            trigger="hover"
                            class="lg:max-w-[300px] max-w-[180px] lg:max-h-[400px] max-h-[180px] rounded-md overflow-auto bg-main"
                            placement="top">
                            <template #trigger>
                                <n-icon class="text-lg cursor-pointer text-main">
                                    <QuestionCircle20Filled />
                                </n-icon>
                            </template>
                            <span class="text-[10px] lg:text-xs text-white" v-html="stats.localization?.calculate_percent_deviation_help || 'Посчитать % отклонения HELP'">
                            </span>
                        </n-popover>
                    </section>
                    </div>
                    
                        
                    <div class="mt-2 flex items-center flex-wrap">
                        {{ stats.localization?.sum_of_all_wallets_text || 'Сумма кошельков' }}:
                        <div v-for="(item, i) in dataSumWallets" :key="item.key" class="flex items-center mx-1 whitespace-nowrap">
                            {{ item.key }}:
                            <div class="text-green-600 font-semibold pl-1">{{ item.value }}</div>
                            <rb-coin class="mx-1 w-4" :coin="currency(item.coin)" />
                            <template v-if="i + 1 < dataSumWallets.length">,</template>
                        </div>
                    </div>
                </section>
            </section>
        </n-card>
    </template>
    <template #actionButtons>
        <section v-if="currentTable.records && stats.isBots" class="flex flex-wrap">
            <n-button
                strong
                class="text-white/90 rounded-md mr-3 mb-2"
                :color="gl.mainColor"
                :disabled="!actionButtonsDisabled"
                @click="letsShowConfirmation('start')">
                {{ stats.localization['trade_statistics_bots_table_mass_start_button'] }}
            </n-button>
            <n-button
                strong
                class="mr-3 mb-2 text-white/90 rounded-md"
                :color="gl.mainColor"
                :disabled="!actionButtonsDisabled"
                @click="letsShowConfirmation('stop')">
                {{ stats.localization['trade_statistics_bots_table_mass_stop_button'] }}
            </n-button>
            <n-button
                strong
                class="mr-3 mb-2 text-white/90 rounded-md"
                :color="gl.mainColor"
                :disabled="!actionButtonsDisabled"
                @click="letsShowConfirmation('pause')">
                {{ stats.localization['trade_statistics_bots_table_mass_pause_button'] }}
            </n-button>
            <n-button
                strong
                class="mr-3 mb-2 text-white/90 rounded-md"
                :color="gl.mainColor"
                :disabled="!actionButtonsDisabled"
                @click="letsShowConfirmation('archive')">
                {{ stats.localization['trade_statistics_bots_table_mass_archive_button'] }}
            </n-button>
            <n-button
                strong
                class="mr-3 mb-2 text-white/90 rounded-md"
                :color="gl.mainColor"
                :disabled="!actionButtonsDisabled"
                @click="letsShowConfirmation('delete')">
                {{ stats.localization['trade_statistics_bots_table_mass_delete_button'] }}
            </n-button>
            <n-button
                strong
                class="mr-3 mb-2 text-white/90 rounded-md"
                :color="gl.mainColor"
                :disabled="!actionButtonsDisabled"
                @click="letsShowConfirmation('markLastAsCanceled')">
                {{ stats.localization['trade_statistics_management_markcyclecanceled_button'] }}
            </n-button>
            <n-button
                strong
                class="mr-3 mb-2 text-white/90 rounded-md"
                :color="gl.mainColor"
                :disabled="!actionButtonsDisabled"
                @click="letsShowConfirmation('restart')">
                {{ stats.localization['trade_statistics_management_restart_button'] }}
            </n-button>
            <n-button
                strong
                class="mr-3 mb-2 text-white/90 rounded-md"
                :color="gl.mainColor"
                :disabled="!actionButtonsDisabled"
                @click="letsShowConfirmation('force_stop')">
                {{ stats.localization['trade_statistics_bots_table_mass_force_stop'] }}
            </n-button>
        </section>
    </template>
</super-table>

<rb-trading-results
    v-if="init"
    :is-modal="true"
    :mode="'bot'"
    :trading-result-data="tradingResultData"
    v-model:show="showModalTR" />

<base-dialog-desktop
    v-model:show="showConfirmation"
    @positive-click="showConfirmation = false, groupAction(actionType)"
    @negative-click="showConfirmation = false">
    <div class="text-md" v-html="refs.localization.confirmations.bot[actionType]"></div>
</base-dialog-desktop>

<bot-clone-more
    :botId="cloneMoreShowId"
    @update:bot-id="cloneMoreShowId = $event"
    :localization="stats.localization"
    @oneBotIsDone="oneBotIsDone($event)" />
</template>

<script>
// general
import general from './general';

// icon
import { ChevronLeft24Filled, QuestionCircle20Filled } from '@vicons/fluent';

// components
import RbCoin from '@components/rb-coin';
import RbSwitch from '@components/rb-switch';
import FilterBots from '@components/filters/bots';
import FilterStates from '@components/filters/states';
import FilterStatuses from '@components/filters/statuses';
import FilterExchanges from '@components/filters/exchanges';
import SuperTable from '@components/super-table/desktop.vue';
import BotCloneMore from '@components/bots/childs/bot-clone-more';
import RbTradingResults from '@components/rb-trading-results';

// UI
import {
    NTag,
    NSpin,
    NIcon,
    NCard,
    NTabs,
    NButton,
    NTabPane,
    NDivider,
    NPopover,
    NSkeleton,
    NScrollbar,
    NDatePicker,
    NRadioGroup,
    NRadioButton } from 'naive-ui';

export default {
    name: 'trade-statistic-desktop',
    components: {
        NTag,
        NSpin,
        NIcon,
        NCard,
        NTabs,
        RbCoin,
        NButton,
        NDivider,
        RbSwitch,
        NTabPane,
        NPopover,
        SuperTable,
        NSkeleton,
        NScrollbar,
        FilterBots,
        NRadioGroup,
        NDatePicker,
        NRadioButton,
        FilterStates,
        BotCloneMore,
        FilterStatuses,
        FilterExchanges,
        RbTradingResults,
        ChevronLeft24Filled,
        QuestionCircle20Filled,
    },
    setup(props, context) {
        
        return {
            ...general(...arguments),
        };
    },
};
</script>