<template>
<rb-trading-results-modal
    v-if="isModal"
    :data="modalProps"
    :show="show"
    @update:show="$emit('update:show', $event)"
    @setDataPeriod="setDataPeriod" />
<template v-else>
    <n-button
        strong
        secondary
        size="small"
        class="rounded-md text-sm"
        @click="showModal = true">
        <n-tag round size="tiny" type="warning" class="mr-2">
            <span class="text-yellow-500 font-semibold text-xs">new</span>
        </n-tag>
        {{ stats.localization?.lets_sharing_stats || 'Поделиться статистикой' }}
    </n-button>

    <rb-trading-results-modal
        :data="modalProps"
        v-model:show="showModal"
        @setDataPeriod="setDataPeriod" />
</template>
</template>

<script>
// general
import general from './general';

// icons
import {
    Copy16Regular,
    ArrowDownload16Filled } from '@vicons/fluent';

// components
import RbTradingResultsModal from './rb-trading-results-modal';

// UI
import {
    NTag,
    NIcon,
    NCard,
    NAlert,
    NButton,
    NDivider,
    NDropdown } from 'naive-ui';

export default {
    name: 'rb-trading-results-mobile',
    props: {
        isModal: {
            type: Boolean,
            default: false,
        },
        uid: {
            type: Boolean,
            default: false,
        },
        show: {
            type: Boolean,
            default: false,
        },
        mode: {
            type: String,
            default: 'date',
        },
        tradingResultData: {
            type: Object,
            required: true,
        },
    },
    components: {
        NTag,
        NIcon,
        NCard,
        NAlert,
        NButton,
        NDivider,
        NDropdown,
        Copy16Regular,
        RbTradingResultsModal,
        ArrowDownload16Filled,
    },
    setup(props, context) {
    
        return {
            ...general(props, context),
        };
    },
};
</script>