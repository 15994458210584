<template>
<section class="px-3 min-h-screen">
    <n-card
        size="small"
        :bordered="false"
        class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg"
        :segmented="{
            content: true,
            footer: 'soft'
        }">
        <template #default>
            <div class="border border-dashed border-gray-600 rounded-md items-center pb-4">
                <div class="flex items-center justify-center px-3 my-2">
                    <ApiKeysIcon class="fill-current w-12" />
                </div>
                <n-button
                    strong
                    class="text-white/90 rounded-md"
                    :color="gl.mainColor"
                    @click="walletApiKeyDialog = true">
                    + {{ apiKeys.localization?.apikeys_title_button }}
                </n-button>
            </div>
        </template>
    </n-card>

    <super-table
        v-if="init"
        showSearch
        :filters="filters"
        :refFilters="refFilters"
        :actions="actions"
        :pager="apiKeys.apiKeys.pager"
        :columns="columns"
        :records="records"
        :loading="loadings.table"
        :filtersChanged="filtersChanged"
        type="apiKeys"
        columnsStorageName="__columns_api_keys"
        @getData="apiKeysGet"
        @sortColumn="sortColumn"
        @doSomething="doSomething"
        @reset-filters="resetFilters"
        @applyFilters="changeFilter">
        <template #tableTitle>
            <div class="text-base text-left mb-2 text-gray-600 dark:text-white/75">
                {{ apiKeys.localization['apikeys_all_keys_title'] }}
            </div>
        </template>
        <template #actionButtons>
            <section v-if="apiKeys.records.length" class="flex mt-4 mb-2">
                <n-button
                    size="tiny"
                    class="text-white/90 rounded-md"
                    :color="gl.mainColor"
                    :disabled="!actionButtonsDisabled"
                    @click="apiKeysDelete(null, true)">
                    {{ apiKeys.localization['apikeys_all_keys_table_mass_delete_button'] }}
                </n-button>
            </section>
        </template>
    </super-table>
    <base-dialog-mobile
        v-model:show="showModalDelete"
        @positive-click="apiKeysDelete"
        @negative-click="showModalDelete = false">
        <div class="text-md" v-html="refs.localization.confirmations.apikey.delete"></div>
    </base-dialog-mobile>

    <rb-api-key-cr
        v-if="init"
        :api-key-id="apiKeyId"
        :show="walletApiKeyDialog"
        :api-keys-list="records"
        @update:show="triggerModal($event)"
        @updateApiKey="updateApiKey"
        @addNewApiKey="addNewApiKey($event)" />
</section>
</template>

<script>
// general
import general from './general';

// components
import SuperTable from '@components/super-table/mobile.vue';
import ApikeysCreate from '@components/apikeys-create/mobile.vue';
import RbApiKeyCr from '@components/rb-api-key-cr';
import ApiKeysIcon from '@components/icons/api-keys.vue';

// UI
import {
    NCard,
    NTabs,
    NButton,
    NTabPane } from 'naive-ui';

export default {
    name: 'ApiKeys',
    components: {
        NCard,
        NTabs,
        NButton,
        NTabPane,
        RbApiKeyCr,
        SuperTable,
        ApiKeysIcon,
        ApikeysCreate,
    },
    setup(props, context) {
        
        return {
            ...general(arguments),
        };
    },
};
</script>