<template>
<div class="p-4">
    <n-card
        size="small"
        :bordered="false"
        class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg"
        :segmented="{
            content: true,
            footer: 'soft'
        }">
        <template #default>
            <div class="border border-dashed border-gray-600 rounded-md flex flex-col items-center py-6">
                <WalletsIcon class="w-24 fill-current" />
                <n-button
                    strong
                    class="mt-4 text-white/90 rounded-md"
                    :color="gl.mainColor"
                    @click="showModal = true">
                    + {{ wallets.localization?.wallets_create_title_button }}
                </n-button>
            </div>
        </template>
    </n-card>
        
    <super-table
        v-if="init"
        showSearch
        :filters="filters"
        :refFilters="refFilters"
        :actions="actions"
        :pager="wallets.wallets.pager"
        :columns="columns"
        :records="wallets.wallets.records"
        :filtersChanged="filtersChanged"
        :loading="loadings.table"
        type="wallets"
        columnsStorageName="__columns_wallets"
        @getData="walletsGet"
        @sortColumn="sortColumn"
        @doSomething="doSomething"
        @reset-filters="resetFilters"
        @applyFilters="changeFilter">
        <template #tableTitle>
            <div class="text-base text-left mb-2 text-gray-600 dark:text-white/75">
                {{ wallets.localization['wallets_all_wallets_title'] }}
            </div>
        </template>
        <template #actionButtons>
            <section v-if="wallets.wallets.records.length" class="flex">
                <n-button
                    class="text-white/90 rounded-md"
                    :color="gl.mainColor"
                    :disabled="!actionButtonsDisabled"
                    @click="deleteWallet(null, true)">
                    {{ wallets.localization['wallets_all_wallets_table_actions_delete'] }}
                </n-button>
            </section>
        </template>
    </super-table>
    <rb-wallet-cr
        :wallet-id="walletId"
        :show="showModal"
        @update:show="triggerModal($event)"
        @updateWallet="updateWallet"
        @addNewWallet="addNewWallet" />
</div>
</template>

<script>
// general
import general from './general';

// components
import FilterCoins from '@components/filters/coins.vue';
import SuperTable from '@components/super-table/desktop.vue';
import FilterExchanges from '@components/filters/exchanges';
import FilterCheckable from '@components/filters/filter-checkable';
import RbWalletCr from '@components/rb-wallet-cr/index.vue';
import WalletsIcon from '@components/icons/wallets.vue';

// UI
import {
    NCard,
    NTabs,
    NModal,
    NButton,
    NTabPane } from 'naive-ui';

export default {
    name: 'wallets_desktop',
    components: {
        NCard,
        NTabs,
        NModal,
        NButton,
        NTabPane,
        RbWalletCr,
        SuperTable,
        FilterCoins,
        WalletsIcon,
        FilterExchanges,
        FilterCheckable,
    },
    setup(props, context) {
        
        return {
            ...general(...arguments),
        };
    },
};
</script>

<style lang="scss" scoped>
:deep(.n-tabs-tab) {
    font-size: 20px;
    font-weight: 600;

    @media screen and (max-width: 1024px) {
        font-size: 16px;
    }
}
</style>