// vue
import { ref, reactive } from 'vue';

// store
import { useRefs } from '@store/ts/refs';

export default function (props, context) {
    // store
    const refs = useRefs();
    // vars
    const showModalTR = ref(false);
    const tradingResultData = reactive({
        botId: props.row?.bot_id,
        cycleId: props.row?.id,
    });

    const currency = icon => refs.currencies.find(el => el.id === icon);

    return {
        currency,
        showModalTR,
        tradingResultData,
    };
}