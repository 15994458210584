<template>
<section class="px-3 min-h-screen">
    <n-card
        size="small"
        :bordered="false"
        class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg"
        :segmented="{
            content: true,
            footer: 'soft'
        }">
        <template #default>
            <div class="border border-dashed border-gray-600 rounded-md items-center pb-4">
                <div class="flex items-center justify-center px-3 my-2">
                    <WalletsIcon class="fill-current w-12" />
                </div>
                <n-button
                    strong
                    class="text-white/90 rounded-md"
                    :color="gl.mainColor"
                    @click="showModal = true">
                    + {{ wallets.localization?.wallets_create_title_button }}
                </n-button>
            </div>
        </template>
    </n-card>
        
    <super-table
        v-if="init"
        showSearch
        :filters="filters"
        :refFilters="refFilters"
        :actions="actions"
        :pager="wallets.wallets.pager"
        :columns="columns"
        :records="wallets.wallets.records"
        :filtersChanged="filtersChanged"
        :loading="loadings.table"
        type="wallets"
        columnsStorageName="__columns_wallets"
        @getData="walletsGet"
        @sortColumn="sortColumn"
        @doSomething="doSomething"
        @reset-filters="resetFilters"
        @applyFilters="changeFilter">
        <template #tableTitle>
            <div class="text-base text-left mb-2 text-gray-600 dark:text-white/75">
                {{ wallets.localization['wallets_all_wallets_title'] }}
            </div>
        </template>
        <template #actionButtons>
            <section v-if="wallets.wallets.records.length" class="mt-4 mb-2 text-left">
                <n-button
                    size="tiny"
                    class="text-white/90 rounded-md"
                    :color="gl.mainColor"
                    :disabled="!actionButtonsDisabled"
                    @click="deleteWallet(true)">
                    {{ wallets.localization['wallets_all_wallets_table_actions_delete'] }}
                </n-button>
            </section>
        </template>
    </super-table>
    <rb-wallet-cr
        :wallet-id="walletId"
        :show="showModal"
        @update:show="triggerModal($event)"
        @updateWallet="updateWallet"
        @addNewWallet="addNewWallet" />
    <base-dialog-mobile
        v-model:show="showModalDelete"
        @positive-click="deleteWallet"
        @negative-click="showModalDelete = false">
        <div class="text-md" v-html="refs.localization.confirmations.wallet.delete"></div>
    </base-dialog-mobile>
</section>
</template>

<script>
// general
import general from './general';

// components
import FilterCoins from '@components/filters/coins.vue';
import SuperTable from '@components/super-table/mobile.vue';
import FilterExchanges from '@components/filters/exchanges';
import FilterCheckable from '@components/filters/filter-checkable';
import RbWalletCr from '@components/rb-wallet-cr/mobile.vue';
import WalletsIcon from '@components/icons/wallets.vue';

// UI
import {
    NCard,
    NTabs,
    NModal,
    NButton,
    NTabPane } from 'naive-ui';

export default {
    name: 'wallets_mobile',
    components: {
        NCard,
        NTabs,
        NModal,
        NButton,
        NTabPane,
        SuperTable,
        RbWalletCr,
        FilterCoins,
        WalletsIcon,
        FilterExchanges,
        FilterCheckable,
    },
    setup(props, context) {
        
        return {
            ...general(...arguments),
        };
    },
};
</script>

<style lang="scss" scoped>
:deep(.n-tabs-tab) {
    font-size: 20px;
    font-weight: 600;

    @media screen and (max-width: 1024px) {
        font-size: 16px;
    }
}
</style>