import Api from './index';

export default class Profile {

    static async markNotificationsAsRead(payload) {
        return await Api.getData({
            payload,
            action: 'profile.notifications.markAsRead',
        });
    };

    static async getRefs() {
        return await Api.getData({
            action: 'profile.getRefs',
        });
    };

    static async getFullInfo() {
        return await Api.getData({
            action: 'profile.getFullInfo',
        });
    };

    static async update(payload) {
        return await Api.getData({
            payload,
            action: 'profile.update',
        });
    };

    static async getTransactions(payload) {
        return await Api.getData({
            payload,
            action: 'profile.getTransactions',
        });
    };

    static async internalTransfer(payload) {
        return await Api.getData({
            payload: {
                transfer: payload,
            },
            action: 'profile.internalTransfer',
        });
    };

    static async oauthProviders(payload) {
        return await Api.getData({
            payload: {
                pager: payload,
            },
            action: 'profile.oauthProviders.get',
        });
    };

    static async oauthProvidersDelete(payload) {
        return await Api.getData({
            payload: {
                ids: payload,
            },
            action: 'profile.oauthProviders.delete',
        });
    };

    static async toggleTgNotifications(payload) {
        return await Api.getData({
            payload,
            action: 'profile.toggleTgNotifications',
        });
    };

    static async adviceMessageMarkAsRead(payload) {
        return await Api.getData({
            payload,
            action: 'profile.adviceMessage.markAsRead',
        });
    };

    static async adviceMessageDeferAll(payload) {
        return await Api.getData({
            payload,
            action: 'profile.adviceMessage.deferAll',
        });
    };

    static async dialogMessageMarkAsRead(payload) {
        return await Api.getData({
            payload,
            action: 'profile.dialogMessage.markAsRead',
        });
    };

    static async logout(payload) {
        return await Api.getData({
            action: 'profile.logout',
        });
    };

    static async privateMessage(payload) {
        return await Api.getData({
            payload,
            action: 'profile.privateMessage',
        });
    };

    static async generateWallet(payload) {
        return await Api.getData({
            payload,
            action: 'profile.generateWallet',
        });
    };

    static async getBonusStepStatus() {
        return await Api.getData({
            action: 'profile.getBonusStepStatus',
        });
    }

    static async addGetUserStats(payload) {
        return await Api.getData({
            payload,
            action: 'profile.addGetUserStats',
        });
    }
};