// vue
import { h, ref, reactive, computed, watch, onMounted } from 'vue';

// services
import WalletsService from '@services/walletsService';
import LocalStorage from '@services/ts/localStorage';

// shared
import { CRUD_PAGER_FACTORY } from '@shared/factories';

// i18n
import { useI18n } from 'vue-i18n';

// store
import { useGl } from '@store/ts/gl';
import { useRefs } from '@store/ts/refs';
import { useWallets } from '@store/wallets';
import { useConfirm } from '@store/confirm';

// router
import { useRoute, useRouter } from 'vue-router';

// icons
import { Edit20Regular, DeleteDismiss24Regular } from '@vicons/fluent';

// UI
import {
    NCheckbox,
    useNotification } from 'naive-ui';

export default function () {
    const route = useRoute();
    const router = useRouter();

    // store
    const gl = useGl();
    const refs = useRefs();
    const wallets = useWallets();
    const confirm = useConfirm();

    // ui
    const notification = useNotification();

    // i18n
    const { t } = useI18n();

    // vars
    const { _ } = window;
    const init = ref(false);
    const walletCreate = ref();
    const walletEditData = ref();
    const showModal = ref(!!route.params.id);
    const loadings = reactive({
        table: false,
    });

    // storage filters
    const storeFilterKey = '___filters_wallets';
    let storeFilters = LocalStorage.getItem(storeFilterKey);

    // set filter settings
    if (storeFilters == null) {
        LocalStorage.setItem(storeFilterKey, CRUD_PAGER_FACTORY({
            currencies: [-1],
            exchanges: [-1],
            apikeys: [-1],
            pairs: [-1],
            statuses: [-1],
            types: [-1],
        }));

        storeFilters = LocalStorage.getItem(storeFilterKey);
    }

    // filters
    const filters = reactive({
        ...storeFilters,
    });

    const filtersChanged = computed(() => {
        return filters.filters.currencies[0] !== -1
                || filters.filters.exchanges[0] !== -1
                || filters.filters.apikeys[0] !== -1
                || filters.filters.pairs[0] !== -1
                || filters.filters.statuses[0] !== -1
                || filters.filters.types[0] !== -1;
    });

    const resetFilters = () => {
        filters.filters.currencies = [ -1 ];
        filters.filters.exchanges = [ -1 ];
        filters.filters.apikeys = [ -1 ];
        filters.filters.pairs = [ -1 ];
        filters.filters.statuses = [ -1 ];
        filters.filters.types = [ -1 ];
            
        walletsGet();
    };

    const refFilters = computed(() => [
        {
            mode: 'exchanges',
            title: refs.localization.filters.wallets.filters_wallets_exchanges_label,
            refs: refs.exchanges,
            filter: 'filters.exchanges',
            seeable: filters.filters.exchanges[0] !== -1,
        }, {
            mode: 'filter_coins',
            title: refs.localization.filters.wallets.filters_wallets_currencies_label,
            refs: refs.currencies,
            value: filters.filters.currencies,
            filter: 'filters.currencies',
            seeable: filters.filters.currencies[0] !== -1,
        }, {
            mode: 'checkbox_group',
            title: refs.localization.filters.wallets.filters_wallets_statuses_label,
            refs: refs.walletStatuses,
            filter: 'filters.statuses',
            seeable: filters.filters.statuses[0] !== -1,
        }, {
            mode: 'checkbox_group',
            title: refs.localization.filters.wallets.filters_wallets_types_label,
            refs: refs.walletTypes,
            filter: 'filters.types',
            seeable: filters.filters.types[0] !== -1,
        },
    ]);

    const walletId = computed(() => route.params.id);
    // const actions = computed(() => ['wallets__edit', 'wallets__delete']);
    const actions = computed(() => [
        {
            id: 'wallets__edit',
            icon: row => Edit20Regular,
            fn: row => doSomething(['walletsEdit', row]),
            popover: row => wallets.localization?.['wallets_all_wallets_table_actions_edit'],
        }, {
            id: 'wallets__delete',
            icon: row => DeleteDismiss24Regular,
            fn: row => doSomething(['walletsDelete', row.id]),
            popover: row => wallets.localization?.['wallets_all_wallets_table_mass_delete_button'],
        },
    ]);
    const actionButtonsDisabled = computed(() => wallets.wallets.records ? wallets.wallets.records.some(({ checked }) => checked) : false);

    const columns = computed(() => {
        if (wallets.columns) {
            const arr = [...wallets.columns].filter(({ title }) => title);

            arr.unshift({
                key: 'checked',
                name: 'checked',
                isHidden: false,
                title() {
                    return h(NCheckbox, {
                        checked: wallets.wallets.records.every(({ checked }) => checked),
                        onUpdateChecked(v) {
                            wallets.wallets.records.forEach(el => el.checked = v);
                        },
                    });
                },
            });

            return arr;
        } else return [];
    });

    watch(() => refs.lang, async () => {
        // await getData();
    });

    const walletsRefs = async (showLoading = true) => {
        if (showLoading)
            gl.showLoading = true;

        try {
            wallets.refs = ( await WalletsService.getRefs() ).data;
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        };
            
        if (showLoading)
            gl.showLoading = false;
    };

    const getFullInfo = async (id, showLoading = [true, true]) => {
        if (showLoading[0])
            gl.showLoading = true;

        try {
            walletEditData.value = ( await WalletsService.getFullInfo(id) ).data;
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        };
            
        if (showLoading[1])
            gl.showLoading = false;
    };

    const walletsGet = async (showLoading = true) => {
        // if (showLoading)
        //     gl.showLoading = true;
        loadings.table = true;
                
        // save filters
        LocalStorage.setItem(storeFilterKey, filters);

        try {
            const prepare = await WalletsService.walletsGet({
                pager: filters,
            });

            if (wallets.wallets) {
                Object.keys(prepare.data).forEach(el => {
                    if(!['columns', 'revenue_stats'].includes(el)) {
                        wallets.wallets[el] = prepare.data[el];
                    }
                });
            } else {
                wallets.wallets = prepare.data;
            }
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        };

        // if (showLoading)
        //     gl.showLoading = false;
        loadings.table = false;
    };

    const getAll = async () => {
        gl.showLoading = true;

        await Promise.allSettled([
            walletsRefs(false),
            walletsGet(false),
        ]);

        gl.showLoading = false;
    };

    const sortColumn = $event => {
        _.set(
            filters,
            'sort_column',
            $event.columnKey !== undefined && $event.order !== undefined
                ? $event.columnKey
                : '');

        _.set(
            filters,
            'sort_dir',
            $event.order !== undefined
                ? $event.order
                : '');

        walletsGet();
    };

    const changeFilter = ([ key, value ], update = false) => {
        const canUpdate = key === 'query' && !value && wallets.pager.query;

        if (key !== 'page') {
            _.set(filters, 'page', 1);
        }

        if (key === 'perPage') {
            LocalStorage.setItem('__filters_wallets', value);
        }

        _.set(filters, key, value !== null && value !== undefined ? value : [ -1 ]);

        if (update || canUpdate)
            walletsGet();
    };

    const doSomething = ([ action, value ]) => {
        const fns = {
            walletsDelete: () => {
                confirm.openConfirm({
                    title: refs.localization.confirmations.wallet.delete,
                    fn: () => deleteWallet(value),
                });
            },
            walletsEdit: async () => {
                router.push({ name: 'wallets', params: { id: value.id }});
                
                setTimeout(() => {
                    showModal.value = true;
                }, 0);
                
            },
        };

        fns[action]();
    };
        
    const deleteWallet = async (id, some = false) => {
        gl.showLoading = true;

        try {
            const ids = some
                ? wallets.wallets.records
                    .filter(({ checked }) => checked)
                    .map(({ id }) => id)
                : [ id ];

            const prepare = await WalletsService.walletsDelete({ ids });

            // show messages
            if (Array.isArray(prepare?.postMessages)) {
                prepare.postMessages.forEach(el => {
                    notification[el.success ? 'success' : 'error']({
                        content: el.msg,
                        duration: 2500,
                        keepAliveOnHover: true,
                    });
                });
            }
            

            if (prepare?.data.status) {
                wallets.wallets.records.forEach(el => el.checked = false);

                await walletsGet(false);
            } else {
                if (Array.isArray(prepare.data?.errors)) {
                prepare.data?.errors.forEach(el => {
                    notification.error({
                        content: el.msg,
                        duration: 2500,
                        keepAliveOnHover: true,
                    });
                });
                }
            }
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        };
            
        gl.showLoading = false;
    };

    const updateWallet = data => {
        const i = wallets.wallets.records.findIndex(({ id }) => id == data.id);

        if (~i)
            for (const [ key, value] of Object.entries(wallets.wallets.records[i])) {
                wallets.wallets.records[i][key] = data[key];
            }
    };

    const addNewWallet = data => {
        wallets.wallets.records.unshift(data);
    };

    const triggerModal = $event => {
        if (!$event) {
            router.push({ name: 'wallets' });
            showModal.value = false;
        }
    };

    onMounted(async () => {
        await getAll();

        if (route.params.id) {
            await getFullInfo(route.params.id);
        }

        init.value = true;
    });

    return {
        gl,
        refs,
        init,
        actions,
        filters,
        wallets,
        columns,
        walletId,
        showModal,
        loadings,
        refFilters,
        walletCreate,
        filtersChanged,
        walletEditData,
        actionButtonsDisabled,
        walletsGet,
        sortColumn,
        changeFilter,
        deleteWallet,
        doSomething,
        updateWallet,
        resetFilters,
        addNewWallet,
        triggerModal,
    };
}