<template>
<rb-trading-results-modal
    v-if="isModal"
    :data="modalProps"
    :show="show"
    @update:show="$emit('update:show', $event)"
    @setDataPeriod="setDataPeriod" />
<section v-else>
    <n-card
        size="small"
        :bordered="false"
        class="overflow-auto shadow bg-white dark:bg-darkCardBg rounded-lg h-full"
        :segmented="{
            content: true,
            footer: 'soft'
        }">
        <section class="h-full">
            <section>
                <section class="flex items-center justify-center">
                    <n-tag round size="small" type="warning" class="mr-2">
                        <span class="text-yellow-500 font-semibold text-sm">new</span>
                    </n-tag>
                    <div class="text-gray-600 dark:text-white/75 font-semibold text-base">
                        {{ stats.localization?.trading_results || 'Торговые результаты' }}
                    </div>
                </section>
                <n-alert class="rounded-md mt-6" type="success" size="small">
                    <div class="notes text-sm">{{ stats.localization?.sharing_info || 'Делитесь своими торговыми результатми с друзьями в социальных сетях' }}</div>
                </n-alert>
                <section class="mt-4 flex">
                    <rb-select
                        class="w-6/12 pr-1"
                        :options="yarsOptions"
                        :placeholder="stats.localization?.year || 'Год'"
                        v-model:value="dataPeriod.year" />

                    <rb-select
                        class="w-6/12 pl-1"
                        :options="monthOptions"
                        :disabled="!monthOptions.length"
                        :placeholder="stats.localization?.month || 'Месяц'"
                        v-model:value="dataPeriod.month" />
                </section>

                <n-button
                    strong
                    secondary
                    size="large"
                    class="rounded-md mt-4 w-full"
                    :loading="loadings.generate"
                    :disabled="generateLinkDisabled || loadings.generate"
                    @click="addGetUserStats()">
                    {{ stats.localization?.generate_link || 'Сгенерировать ссылку' }}
                </n-button>
            </section>
        </section>
    </n-card>
    
    <rb-trading-results-modal
        :data="modalProps"
        v-model:show="showModal"  />
</section>
</template>

<script>
// general
import general from './general';

// components
import RbTradingResultsModal from './rb-trading-results-modal';

// UI
import {
    NTag,
    NCard,
    NAlert,
    NButton } from 'naive-ui';

export default {
    name: 'rb-trading-results-desktop',
    props: {
        isModal: {
            type: Boolean,
            default: false,
        },
        uid: {
            type: Boolean,
            default: false,
        },
        show: {
            type: Boolean,
            default: false,
        },
        mode: {
            type: String,
            default: 'date',
        },
        tradingResultData: {
            type: Object,
            required: true,
        },
    },
    components: {
        NTag,
        NCard,
        NAlert,
        NButton,
        RbTradingResultsModal,
    },
    setup(props, context) {
    
        return {
            ...general(props, context),
        };
    },
};
</script>